<template>
    <div>
        <!-- {{ total_data_overview}} -->
        <div
            class="sticky top-0 left-0 component-box !py-3 z-40 w-full bg-white shadow-sm"
        >
            <div class="flex items-center">
                <p class="font-semibold">
                    {{ $t('common.report_date') }}
                </p>

                <el-button
                    icon="el-icon-refresh"
                    size="small"
                    class="ml-2"
                    @click="refreshData"
                ></el-button>

                <div class="!ml-auto"></div>

                <div class="flex items-center ml-2 space-x-2">
                    <!-- <el-switch
                        v-model="switch_compare"
                        :active-text="$t('common.compare')"
                    >
                    </el-switch> -->

                    <div class="relative">
                        <el-date-picker
                            v-model="ranger_date"
                            class="w-[360px]"
                            popper-class="custom-el-date"
                            size="small"
                            type="daterange"
                            unlink-panels
                            :start-placeholder="
                                $t('date_picker.placeholder.start')
                            "
                            :end-placeholder="$t('date_picker.placeholder.end')"
                            :format="$t('common.format_value_date_picker')"
                            value-format="yyyy-MM-dd"
                            :picker-options="m__picker_options"
                        >
                        </el-date-picker>

                        <div
                            v-show="switch_compare"
                            class="absolute top-[98%] -left-2 z-[20] px-2 pb-2 pt-0 mt-1"
                        >
                            <el-date-picker
                                v-model="compare_ranger_date"
                                class="w-[360px]"
                                popper-class="custom-el-date"
                                size="small"
                                type="daterange"
                                unlink-panels
                                :start-placeholder="
                                    $t('date_picker.placeholder.start')
                                "
                                :end-placeholder="
                                    $t('date_picker.placeholder.end')
                                "
                                :format="$t('common.format_value_date_picker')"
                                value-format="yyyy-MM-dd"
                                :picker-options="m__picker_options"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-tabs v-model="main_tab" type="card" class="tiktok-ads-tabs mt-4">
            <el-tab-pane
                v-for="item in adsAccountsWithTotal"
                :key="item.advertiser_id"
                :label="item.name"
                :name="item.advertiser_id"
            >
            </el-tab-pane>
        </el-tabs>

        <div>
            <div v-for="item in adsAccountsWithTotal" :key="item.advertiser_id">
                <ChartWrapper
                    v-show="main_tab === item.advertiser_id"
                    :data_overview="findDataOverview(item.advertiser_id)"
                    :compare_data_overview="
                        findCompareDataOverview(item.advertiser_id)
                    "
                    :ranger_date="ranger_date"
                    :compare_ranger_date="compare_ranger_date"
                    :switch_compare="switch_compare"
                    :tiktok_account_id="tiktok_account_id"
                    :ads_account="item"
                    :format_date="
                        ranger_date[0] === ranger_date[1]
                            ? $t('common.format_fulldate')
                            : $t('common.format_date')
                    "
                    :compare_format_date="
                        compare_ranger_date[0] === compare_ranger_date[1]
                            ? $t('common.format_fulldate')
                            : $t('common.format_date')
                    "
                    :device_model_options="device_model_options"
                    :setDataAllAdvertiserReportType="
                        setDataAllAdvertiserReportType
                    "
                    :data_report_total="data_report_total"
                ></ChartWrapper>
            </div>

            <div
                v-if="ads_accounts.length === 0"
                class="h-[360px] util-flex-center component-box"
            >
                <NoData class="h-[240px] w-full"></NoData>
            </div>
        </div>

        <div class="component-box" :class="[switch_compare ? 'mt-6' : 'mt-4']">
            <!-- <div class="flex space-x-3 overflow-x-auto">
                <div
                    v-for="(item, index) in p__trend_basic_chart_options"
                    :key="index"
                    class="w-[160px] flex-shrink-0 bg-white rounded-lg flex border-border border-[1px] overflow-hidden cursor-pointer"
                >
                    <div class="py-2 ml-4">
                        <div class="flex items-center space-x-1">
                            <p class="text-xs font-semibold">
                                {{ p__getNameByTrend(item.value) }}
                            </p>
                            <el-tooltip effect="dark" placement="top">
                                <div slot="content" class="max-w-[360px]">
                                    <p
                                        v-html="
                                            $t(
                                                `page.campaign.description_column_${item.value}`
                                            )
                                        "
                                    ></p>
                                </div>

                                <i
                                    class="el-icon-info text-desc-text text-sm"
                                ></i>
                            </el-tooltip>
                        </div>

                        <p
                            class="text-brand-atosa--light mt-1 text-base font-semibold"
                        >
                            {{
                                formatterColumn(
                                    total_data_overview
                                        ? Number(
                                              total_data_overview[item.value]
                                          )
                                        : 0,
                                    item.value,
                                    s__currency
                                )
                            }}
                        </p>

                        <p
                            v-if="switch_compare"
                            class="text-desc-text mt-1 text-sm"
                        >
                            {{
                                formatterColumn(
                                    compare_total_data_overview
                                        ? Number(
                                              compare_total_data_overview[
                                                  item.value
                                              ]
                                          )
                                        : 0,
                                    item.value,
                                    s__currency
                                )
                            }}
                        </p>
                    </div>
                </div>
            </div> -->

            <AdAccountTable
                class="mt-1"
                :data_overview="data_overview"
                :currency="s__currency"
                :ranger_date="ranger_date"
            ></AdAccountTable>

            <div v-if="switch_compare">
                <p class="mt-2 text-sm italic text-center">
                    {{
                        ranger_date[0]
                            | f__format_moment(
                                ranger_date[0] === ranger_date[1]
                                    ? $t('common.format_fulldate')
                                    : $t('common.format_date')
                            )
                    }}
                    -
                    {{
                        ranger_date[1]
                            | f__format_moment(
                                ranger_date[0] === ranger_date[1]
                                    ? $t('common.format_fulldate')
                                    : $t('common.format_date')
                            )
                    }}
                </p>

                <AdAccountTable
                    class="mt-4"
                    :data_overview="compare_data_overview"
                    :currency="s__currency"
                    :ranger_date="compare_ranger_date"
                ></AdAccountTable>

                <p class="mt-2 text-sm italic text-center">
                    {{
                        compare_ranger_date[0]
                            | f__format_moment(
                                compare_ranger_date[0] ===
                                    compare_ranger_date[1]
                                    ? $t('common.format_fulldate')
                                    : $t('common.format_date')
                            )
                    }}
                    -
                    {{
                        compare_ranger_date[1]
                            | f__format_moment(
                                compare_ranger_date[0] ===
                                    compare_ranger_date[1]
                                    ? $t('common.format_fulldate')
                                    : $t('common.format_date')
                            )
                    }}
                </p>
            </div>
        </div>

        <div class="flex my-6">
            <div class="w-[200px] h-[6px] rounded mx-auto bg-white"></div>
        </div>
    </div>
</template>

<script>
import rangerDateMixin from '@/plugins/mixins/ranger-date'
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import moment from 'moment'
import ChartWrapper from './chart-wrapper'
import { getAllDeviceModels } from '@/services/atosa-tiktok-ads/tool'
import AdAccountTable from './ad-account-table'

export default {
    components: {
        AdAccountTable,
        ChartWrapper
    },

    mixins: [rangerDateMixin],

    props: ['ads_accounts', 'tiktok_account'],

    data() {
        return {
            main_tab: '1',
            switch_compare: false,
            ranger_date: [
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 30),
                new Date()
            ],
            compare_ranger_date: [
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 15),
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 8)
            ],
            data_overview: [],
            compare_data_overview: [],
            total_data_overview: null,
            compare_total_data_overview: null,
            device_model_options: [],
            data_all_advertiser: {
                report_type: {
                    video: [],
                    live: [],
                    product: []
                }
            }
        }
    },

    computed: {
        tiktok_account_id() {
            return this.tiktok_account ? this.tiktok_account.id : ''
        },

        adsAccountsWithTotal() {
            return [
                ...this.ads_accounts,
                { advertiser_id: 'total', name: 'Tổng' }
            ]
        },

        s__currency() {
            return this.$store.getters.app.s__currency
        },

        data_report_total() {
            const aggregatedByDate = {}

            this.data_all_advertiser.report_type.video.forEach((item) => {
                const dailyTable = item.data

                dailyTable.forEach((entry) => {
                    const { stat_time_day } = entry.dimensions
                    const metrics = entry.metrics

                    // Nếu ngày chưa có trong aggregatedByDate, khởi tạo một đối tượng mới
                    if (!aggregatedByDate[stat_time_day]) {
                        aggregatedByDate[stat_time_day] = {
                            metrics: {},
                            dimensions: { stat_time_day }
                        }
                    }

                    // Cộng dồn các trường trong metrics
                    Object.keys(metrics).forEach((key) => {
                        aggregatedByDate[stat_time_day].metrics[key] = (
                            parseInt(
                                aggregatedByDate[stat_time_day].metrics[key] ||
                                    0,
                                10
                            ) + parseInt(metrics[key], 10)
                        ).toString()
                    })
                })
            })

            // Chuyển aggregatedByDate thành mảng daily_table
            const aggregatedDailyTable = Object.values(aggregatedByDate)

            console.log(aggregatedDailyTable, 'video dálkfjdsalkfj')

            const combineReportTotal = (data_combine) => {
                const aggregatedByDate = {}

                console.log(data_combine, 'dsafdsaf')

                data_combine.forEach((item) => {
                    const dailyTable = item.data

                    dailyTable.forEach((entry) => {
                        const { stat_time_day } = entry.dimensions
                        const metrics = entry.metrics

                        // Nếu ngày chưa có trong aggregatedByDate, khởi tạo một đối tượng mới
                        if (!aggregatedByDate[stat_time_day]) {
                            aggregatedByDate[stat_time_day] = {
                                metrics: {},
                                dimensions: { stat_time_day }
                            }
                        }

                        // Cộng dồn các trường trong metrics
                        Object.keys(metrics).forEach((key) => {
                            aggregatedByDate[stat_time_day].metrics[key] = (
                                parseInt(
                                    aggregatedByDate[stat_time_day].metrics[
                                        key
                                    ] || 0,
                                    10
                                ) + parseInt(metrics[key], 10)
                            ).toString()
                        })
                    })
                })

                // Chuyển aggregatedByDate thành mảng daily_table
                const aggregatedDailyTable = Object.values(aggregatedByDate)

                return aggregatedDailyTable
            }

            const data_video = combineReportTotal(
                this.data_all_advertiser.report_type.video
            )

            const data_live = combineReportTotal(
                this.data_all_advertiser.report_type.live
            )

            const data_product = combineReportTotal(
                this.data_all_advertiser.report_type.product
            )

            return {
                data_daily_identity: {
                    video: data_video,
                    live: data_live,
                    product: data_product
                }
            }
        }
    },

    watch: {
        ads_accounts() {
            this.refreshData()

            if (this.ads_accounts.length > 0) {
                this.main_tab =
                    this.ads_accounts[
                        this.ads_accounts.length - 1
                    ].advertiser_id
            }
        },

        ranger_date() {
            this.getData()
        },

        compare_ranger_date() {
            this.getCompareData()
        },

        switch_compare() {
            if (this.switch_compare) {
                const start_date = moment(this.ranger_date[0])
                const end_date = moment(this.ranger_date[1])

                const days_diff = end_date.diff(start_date, 'days')

                const second_day = start_date.subtract(1, 'days')
                const first_day = moment(second_day).subtract(days_diff, 'days')

                this.compare_ranger_date = [
                    first_day.format('YYYY-MM-DD'),
                    second_day.format('YYYY-MM-DD')
                ]
            }
        }
    },

    mounted() {
        setTimeout(() => {
            this.refreshData()
        }, 1000)

        this.getDeviceModelOptions()
    },

    methods: {
        async getDeviceModelOptions() {
            const response = await getAllDeviceModels()
            this.device_model_options = response.data
        },

        refreshData() {
            this.getData()

            this.getCompareData()
        },

        getData() {
            this.fetchOverviewReport(
                this.ranger_date[0],
                this.ranger_date[1],
                false
            )
        },

        getCompareData() {
            this.fetchOverviewReport(
                this.compare_ranger_date[0],
                this.compare_ranger_date[1],
                true
            )
        },

        findDataOverview(advertiser_id) {
            if (advertiser_id === 'total') {
                return this.total_data_overview
            }

            const temp = this.data_overview.find(
                (item) => `${item.advertiser_id}` === `${advertiser_id}`
            )

            if (temp) {
                return temp.metrics
            }

            return null
        },

        findCompareDataOverview(advertiser_id) {
            const temp = this.compare_data_overview.find(
                (item) => `${item.advertiser_id}` === `${advertiser_id}`
            )

            if (temp) {
                return temp.metrics
            }

            return null
        },

        formatterColumn(value, column_property, currency) {
            if (
                [
                    'cpc',
                    'cpm',
                    'spend',
                    'cost_per_conversion',
                    'cost_per_result',
                    'real_time_cost_per_conversion',
                    'real_time_cost_per_result',
                    'cost_per_1000_reached',
                    'bid_price',
                    'conversion_bid_price',
                    'adgroup.conversion_bid_price'
                ].some((item) => item === column_property)
            ) {
                return this.p__renderMoneyByCurrency(value, currency)
            }

            if (
                ['impressions', 'clicks', 'conversion'].some(
                    (item) => item === column_property
                )
            ) {
                return this.p__formatNumberHasCommas(value)
            }

            if (
                [
                    'ctr',
                    'result_rate',
                    'conversion_rate',
                    'real_time_conversion_rate',
                    'real_time_result_rate',
                    'profile_visits_rate',
                    'anchor_click_rate',
                    'ix_page_viewrate_avg',
                    'engagement_rate'
                ].some((item) => item === column_property)
            ) {
                return `${value}%`
            }

            return value
        },

        async fetchOverviewReport(
            start_date,
            end_date,
            is_compare,
            query_lifetime = false
        ) {
            if (!this.tiktok_account || this.ads_accounts.length === 0) {
                return
            }

            this.m__loading = true

            try {
                const response_list = []

                const temp_start_date = moment(start_date).format('YYYY-MM-DD')
                const temp_end_date = moment(end_date).format('YYYY-MM-DD')

                await Promise.all(
                    this.ads_accounts.map(async (item) => {
                        const data = {
                            report_type: 'BASIC',
                            metrics: this.p__basic_metrics_gmv,
                            start_date: temp_start_date,
                            end_date: temp_end_date,
                            service_type: 'AUCTION',
                            data_level: 'AUCTION_ADVERTISER',
                            advertiser_id: item.advertiser_id,
                            dimensions: ['advertiser_id'],
                            query_lifetime
                        }

                        const response = await getSyncReport(
                            this.tiktok_account.id,
                            data
                        )

                        if (
                            response.code === 0 &&
                            response.data.list.length > 0
                        ) {
                            response_list.push({
                                ...item,
                                metrics: response.data.list[0].metrics,
                                advertiser_id: item.advertiser_id
                            })
                        }
                    })
                )

                const result = this.p__calculateMetrics(
                    response_list.map((item) => item.metrics)
                )

                if (is_compare) {
                    this.compare_total_data_overview = result
                    this.compare_data_overview = response_list
                } else {
                    this.total_data_overview = result
                    this.data_overview = response_list
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        setDataAllAdvertiserReportType(advertiser_id, data, report_type) {
            const data_advertiser = {
                advertiser_id,
                data
            }

            console.log(
                advertiser_id,
                report_type,
                '=========== ádfdsafads========================='
            )

            // Tìm phần tử có cùng advertiser_id trong mảng
            const index = this.data_all_advertiser.report_type[
                report_type
            ].findIndex((i) => i.advertiser_id === advertiser_id)

            if (index !== -1) {
                // Nếu tìm thấy, cập nhật giá trị tại vị trí đó
                this.data_all_advertiser.report_type[report_type][index] =
                    data_advertiser
            } else {
                // Nếu không tìm thấy, thêm phần tử mới vào mảng
                this.data_all_advertiser.report_type[report_type].push(
                    data_advertiser
                )
            }

            console.log('====================================')
            console.log(
                this.data_all_advertiser.report_type[report_type],
                'sdafsdaffds  this.data_all_advertiser'
            )
            console.log('====================================')
        },

        async combineReportTotal(data_combine) {
            const aggregatedByDate = {}

            console.log(data_combine, 'dsafdsaf')

            data_combine.forEach((item) => {
                const dailyTable = item.data

                dailyTable.forEach((entry) => {
                    const { stat_time_day } = entry.dimensions
                    const metrics = entry.metrics

                    // Nếu ngày chưa có trong aggregatedByDate, khởi tạo một đối tượng mới
                    if (!aggregatedByDate[stat_time_day]) {
                        aggregatedByDate[stat_time_day] = {
                            metrics: {},
                            dimensions: { stat_time_day }
                        }
                    }

                    // Cộng dồn các trường trong metrics
                    Object.keys(metrics).forEach((key) => {
                        aggregatedByDate[stat_time_day].metrics[key] = (
                            parseInt(
                                aggregatedByDate[stat_time_day].metrics[key] ||
                                    0,
                                10
                            ) + parseInt(metrics[key], 10)
                        ).toString()
                    })
                })
            })

            // Chuyển aggregatedByDate thành mảng daily_table
            const aggregatedDailyTable = Object.values(aggregatedByDate)

            return aggregatedDailyTable
        }
    }
}
</script>
