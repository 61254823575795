<template>
    <section class="relative p-4 mt-[2px] bg-white">
        <!-- {{ p__trend_basic_chart_options_gmv}} -->
        <div v-if="ads_account" class="flex items-center mb-4">
            <p
                class="text-sm"
                v-html="
                    $t('page.dashboard.overview_ads_xxx', {
                        xxx: ads_account.name
                    })
                "
            ></p>

            <el-button
                type="success"
                plain
                icon="el-icon-money"
                class="ml-auto"
                size="small"
                @click="
                    $router.push({
                        path: `${p__group_route.ads_manager}/${p__route.payment}?advertiser_id=${ads_account.advertiser_id}`
                    })
                "
            >
                {{ $t('page.payment.add_balance') }}
            </el-button>

            <el-button
                plain
                size="small"
                @click="
                    $router.push({
                        path: `${p__group_route.ads_manager}/${p__route.campaign}?advertiser_id=${ads_account.advertiser_id}`
                    })
                "
            >
                {{ $t('button.see_details') }}
            </el-button>

            <el-button
                plain
                icon="el-icon-plus"
                size="small"
                class="ml-2"
                @click="
                    $router.push({
                        path: `${p__group_route.ads_manager}/${p__route.create_campaign}?advertiser_id=${ads_account.advertiser_id}`
                    })
                "
            >
                {{ $t('button.create_campaign') }}
            </el-button>
        </div>

        <div class="flex pb-2 space-x-3 overflow-x-auto">
            <div
                v-for="(item, index) in p__trend_basic_chart_options_gmv"
                :key="index"
                class="w-[160px] flex-shrink-0 bg-bg rounded-lg flex overflow-hidden cursor-pointer"
                :class="[
                    hasSelectedTrend(item.value)
                        ? ''
                        : 'opacity-70 hover:opacity-800 group'
                ]"
                @click="handleChangeSelectedTrend(item.value)"
            >
                <div
                    class="w-1 h-full"
                    :style="{
                        backgroundColor: getColorByTrend(item.value)
                    }"
                    :class="[
                        hasSelectedTrend(item.value)
                            ? ''
                            : 'invisible group-hover:visible'
                    ]"
                ></div>
                <div class="py-2 ml-4">
                    <div class="flex items-center space-x-1">
                        <p class="text-xs font-semibold">
                            {{ p__getNameByTrend(item.value) }}
                        </p>
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content" class="max-w-[360px]">
                                <p
                                    v-html="
                                        $t(
                                            `page.campaign.description_column_${item.value}`
                                        )
                                    "
                                ></p>
                            </div>

                            <i class="el-icon-info text-desc-text text-sm"></i>
                        </el-tooltip>
                    </div>

                    <p
                        :style="{ color: getColorByTrend(item.value) }"
                        class="mt-1 text-base font-semibold"
                    >
                        {{
                            m__formatterColumn(
                                data_overview ? data_overview[item.value] : 0,
                                item.value,
                                s__currency
                            )
                        }}
                    </p>

                    <p
                        v-if="switch_compare"
                        class="text-desc-text mt-1 text-sm"
                    >
                        {{
                            m__formatterColumn(
                                compare_data_overview
                                    ? compare_data_overview[item.value]
                                    : 0,
                                item.value,
                                s__currency
                            )
                        }}
                    </p>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <!-- <div v-loading="m__loading" class="relative">
                <div
                    v-if="data_list.length && ranger_date[0] && ranger_date[1]"
                >
                    <DailyChart
                        :data_list="data_list"
                        :format_date="format_date"
                        :selected_trends="selected_trends"
                    ></DailyChart>

                    <p
                        v-if="switch_compare"
                        class="mt-4 text-sm italic text-center"
                    >
                        {{ ranger_date[0] | f__format_moment(format_date) }} -
                        {{ ranger_date[1] | f__format_moment(format_date) }}
                    </p>
                </div>

                <div v-else class="h-[280px]">
                    <div
                        class="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2"
                    >
                        <div class="util-flex-center flex-col">
                            <img
                                class="w-[140px] h-[140px]"
                                :src="require('@/assets/images/no_data.png')"
                            />
                            <p class="text-desc-text mt-2 text-sm">
                                {{ $t('common.no_data') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div> -->

            <div
                v-if="switch_compare"
                v-loading="m__loading"
                class="relative mt-4"
            >
                <div v-if="compare_data_list.length">
                    <DailyChart
                        :data_list="compare_data_list"
                        :format_date="compare_format_date"
                        :selected_trends="selected_trends"
                    ></DailyChart>

                    <p
                        v-if="switch_compare"
                        class="mt-4 text-sm italic text-center"
                    >
                        {{
                            compare_ranger_date[0]
                                | f__format_moment(format_date)
                        }}
                        -
                        {{
                            compare_ranger_date[1]
                                | f__format_moment(format_date)
                        }}
                    </p>
                </div>

                <div v-else class="h-[280px]">
                    <div
                        class="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2"
                    >
                        <div class="util-flex-center flex-col">
                            <img
                                class="w-[140px] h-[140px]"
                                :src="require('@/assets/images/no_data.png')"
                            />
                            <p class="text-desc-text mt-2 text-sm">
                                {{ $t('common.no_data') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-bg w-full h-[1px] my-4"></div>

            <DailyTable
                v-loading="m__loading"
                :title="$t('page.campaign.video_shopping')"
                :data_list="data_daily_identity_c.video"
                :compare_data_list="compare_data_list"
                :currency="s__currency"
                :advertiser_id="ads_account.advertiser_id"
                :metrics="chart_options.map((item) => item.value)"
                :switch_compare="switch_compare"
                :ranger_date="ranger_date"
                :compare_ranger_date="compare_ranger_date"
                :format_date="format_date"
                :compare_format_date="compare_format_date"
            ></DailyTable>

            <DailyTable
                v-loading="m__loading"
                :title="$t('page.campaign.live_shopping')"
                :data_list="data_daily_identity_c.live"
                :compare_data_list="compare_data_list"
                :currency="s__currency"
                :advertiser_id="ads_account.advertiser_id"
                :metrics="chart_options.map((item) => item.value)"
                :switch_compare="switch_compare"
                :ranger_date="ranger_date"
                :compare_ranger_date="compare_ranger_date"
                :format_date="format_date"
                :compare_format_date="compare_format_date"
            ></DailyTable>

            <DailyTable
                v-loading="m__loading"
                :title="$t('page.campaign.product_shopping_ads')"
                :data_list="data_daily_identity_c.product"
                :compare_data_list="compare_data_list"
                :currency="s__currency"
                :advertiser_id="ads_account.advertiser_id"
                :metrics="chart_options.map((item) => item.value)"
                :switch_compare="switch_compare"
                :ranger_date="ranger_date"
                :compare_ranger_date="compare_ranger_date"
                :format_date="format_date"
                :compare_format_date="compare_format_date"
            ></DailyTable>

            <div class="bg-bg w-full h-[1px] my-4"></div>

            <div v-if="!checkIsTotalTab()">
                <div class="flex items-center mb-2">
                    <p class="text-sm font-semibold">Thống kê</p>
                </div>

                <StatisticalTable
                    :currency="s__currency"
                    :tiktok_account_id="tiktok_account_id"
                    :advertiser_id="ads_account.advertiser_id"
                    :ranger_date="ranger_date"
                ></StatisticalTable>
            </div>

            <!-- <div class="bg-bg w-full h-[1px] my-4"></div>

            <p class="mb-2 text-sm font-semibold">
                {{ $t('page.campaign.dayparting') }}
            </p>

            <Dayparting
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="ads_account.advertiser_id"
                :currency="s__currency"
                :switch_compare="switch_compare"
                :ranger_date="ranger_date"
                :compare_ranger_date="compare_ranger_date"
                :format_date="format_date"
                :compare_format_date="compare_format_date"
                :location_options="location_options"
                :device_model_options="device_model_options"
                :interest_category_options="interest_category_options"
            ></Dayparting> -->

            <ProposalDialog
                :visible.sync="show_proposal_dialog"
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="ads_account.advertiser_id"
                :ranger_date="ranger_date"
                :device_model_options="device_model_options"
                :interest_category_options="interest_category_options"
            >
            </ProposalDialog>
        </div>
    </section>
</template>

<script>
import DailyChart from './daily-chart'
import Audience from './audience'
import AnalystAudience from './analyst-audience'
import DailyTable from './daily-table'
import ProposalDialog from './proposal-dialog'
import StatisticalTable from './statistical-table'
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'
import moment from 'moment'
import { getQueryTool, getRegions } from '@/services/atosa-tiktok-ads/tool'
import { p__trend_basic_chart_options_gmv } from '@/plugins/prototypes/metrics'

export default {
    components: {
        // Dayparting,
        StatisticalTable,
        ProposalDialog,
        DailyChart,
        // Audience,
        // AnalystAudience,
        DailyTable
    },

    mixins: [campaignMixin],

    props: [
        'ads_account',
        'tiktok_account_id',
        'switch_compare',
        'data_overview',
        'compare_data_overview',
        'format_date',
        'compare_format_date',
        'ranger_date',
        'compare_ranger_date',
        'device_model_options',
        'setDataAllAdvertiserReportType',
        'data_report_total'
    ],

    data() {
        const chart_options = []

        this.p__view_data_metric_options_gmv.forEach((item) => {
            item.options.forEach((it) => {
                chart_options.push(it)
            })
        })

        return {
            show_proposal_dialog: false,
            chart_options,
            data_list: [],
            compare_data_list: [],
            selected_trends: [],
            region_options: [],
            interest_category_options: [],
            number_screen_audience: [true],
            number_screen_analyst_audience: [true],
            data_daily_identity: {
                video: [],
                live: [],
                product: []
            }
        }
    },

    computed: {
        s__currency() {
            return this.$store.getters.app.s__currency
        },

        s__language() {
            return this.$store.getters.app.s__language
        },

        data_daily_identity_c() {
            if (this.checkIsTotalTab()) {
                return this.data_report_total.data_daily_identity
            } else {
                return this.data_daily_identity
            }
        }
    },

    watch: {
        ranger_date() {
            this.fetchDailyData()
        },

        compare_ranger_date() {
            this.fetchCompareDailyData()
        },

        switch_compare() {
            if (this.switch_compare) {
                this.fetchCompareDailyData()
            }
        }
    },

    mounted() {
        setTimeout(() => {
            this.fetchDailyData()
        }, 1111)

        this.selected_trends = this.p__trend_basic_chart_options_gmv
            .slice(0, 4)
            .map((trend) => trend.value)

        // this.getInterestCategoryOptions()

        // this.getRegionOptions()
    },

    methods: {
        async getRegionOptions() {
            const response = await getRegions(this.tiktok_account_id, {
                advertiser_id: this.ads_account.advertiser_id,
                language: this.s__language
            })
            this.region_options = response.data
        },

        async getInterestCategoryOptions() {
            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'interest_category/',
                params: {
                    advertiser_id: this.ads_account.advertiser_id,
                    language: this.$i18n.locale
                }
            })
            this.interest_category_options = response.data.interest_categories
        },

        hasSelectedTrend(value) {
            const trend = this.selected_trends.find((item) => item === value)

            if (trend) {
                return true
            }
            return false
        },

        getColorByTrend(value) {
            const trend = this.p__trend_basic_chart_options_gmv.find(
                (item) => item.value === value
            )

            if (trend) {
                return trend.color
            }

            return 'red'
        },

        handleChangeSelectedTrend(value) {
            const index = this.selected_trends.findIndex(
                (item) => item === value
            )
            if (index > -1) {
                if (this.selected_trends.length === 1) {
                    return
                }
                this.selected_trends.splice(index, 1)
            } else {
                this.selected_trends = [...this.selected_trends, value]
            }

            localStorage.setItem(
                `storage_selected_trends`,
                JSON.stringify(this.selected_trends)
            )
        },

        async fetchDailyData() {
            if (this.ads_account.advertiser_id === 'total') {
                return
            }

            const response = await this.fetchSyncReport(this.ranger_date)

            const response_video = await this.fetchSyncReportByAdEntityType(
                this.ranger_date,
                'VIDEO_SHOPPING_ADS'
            )

            const response_live = await this.fetchSyncReportByAdEntityType(
                this.ranger_date,
                'LIVE_SHOPPING_ADS'
            )

            const response_product = await this.fetchSyncReportByAdEntityType(
                this.ranger_date,
                'PRODUCT_SHOPPING_ADS'
            )

            this.data_daily_identity = {
                video: response_video,
                live: response_live,
                product: response_product
            }

            this.setDataAllAdvertiserReportType(
                this.ads_account.advertiser_id,
                response_video,
                'video'
            )

            this.setDataAllAdvertiserReportType(
                this.ads_account.advertiser_id,
                response_live,
                'live'
            )

            this.setDataAllAdvertiserReportType(
                this.ads_account.advertiser_id,
                response_product,
                'product'
            )

            response.sort((a, b) =>
                moment(a.dimensions.stat_time_day).diff(
                    moment(b.dimensions.stat_time_day)
                )
            )
            response_video.sort((a, b) =>
                moment(a.dimensions.stat_time_day).diff(
                    moment(b.dimensions.stat_time_day)
                )
            )
            response_live.sort((a, b) =>
                moment(a.dimensions.stat_time_day).diff(
                    moment(b.dimensions.stat_time_day)
                )
            )
            response_product.sort((a, b) =>
                moment(a.dimensions.stat_time_day).diff(
                    moment(b.dimensions.stat_time_day)
                )
            )

            this.data_list = response
        },

        async fetchCompareDailyData() {
            const response = await this.fetchSyncReport(
                this.compare_ranger_date
            )
            response.sort((a, b) =>
                moment(a.dimensions.stat_time_day).diff(
                    moment(b.dimensions.stat_time_day)
                )
            )
            this.compare_data_list = response
        },

        async fetchSyncReport(ranger_date) {
            await this.p__wait(1234)

            const arr_30days = this.p__getArr30days(ranger_date)

            const result = []

            this.m__loading = true

            try {
                await Promise.all(
                    arr_30days.map(async (new_ranger_date) => {
                        const start_date = new_ranger_date[0]
                        const end_date = new_ranger_date[1]

                        const response = await getSyncReport(
                            this.tiktok_account_id,
                            {
                                page: 1,
                                page_size: 40,
                                report_type: 'BASIC',
                                data_level: 'AUCTION_ADVERTISER',
                                metrics: this.chart_options.map(
                                    (item) => item.value
                                ),
                                start_date,
                                end_date,
                                advertiser_id: this.ads_account.advertiser_id,
                                dimensions: ['stat_time_day', 'advertiser_id']
                            }
                        )
                        if (
                            response.code === 0 &&
                            response.data.list.length > 0
                        ) {
                            result.push(...response.data.list)
                        }
                    })
                )
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false

            return result
        },

        async fetchSyncReportByAdEntityType(ranger_date, ad_entity_type) {
            await this.p__wait(1234)

            const arr_30days = this.p__getArr30days(ranger_date)

            const result = []

            this.m__loading = true

            try {
                await Promise.all(
                    arr_30days.map(async (new_ranger_date) => {
                        const start_date = new_ranger_date[0]
                        const end_date = new_ranger_date[1]

                        const response = await getSyncReport(
                            this.tiktok_account_id,
                            {
                                page: 1,
                                page_size: 40,
                                report_type: 'BASIC',
                                data_level: 'AUCTION_CAMPAIGN',
                                metrics: this.chart_options.map(
                                    (item) => item.value
                                ),
                                start_date,
                                end_date,
                                advertiser_id: this.ads_account.advertiser_id,
                                dimensions: ['stat_time_day'],
                                filtering: [
                                    {
                                        filter_value: JSON.stringify([
                                            ad_entity_type
                                        ]),
                                        field_name: 'ad_entity_type',
                                        filter_type: 'IN'
                                    }
                                ]
                            }
                        )
                        if (
                            response.code === 0 &&
                            response.data.list.length > 0
                        ) {
                            result.push(...response.data.list)
                        }
                    })
                )
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false

            return result
        },

        checkIsTotalTab() {
            return this.ads_account.advertiser_id === 'total'
        }
    }
}
</script>
